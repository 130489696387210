body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
	background-color: #fcfdff;
}

.root-container {
	height: 95vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

/** 
 * Header 
 */

#title {
	margin-left: 32px;

	font-size: 1.2rem;
	font-family: 'Lato', sans-serif;
}

#border-rect {
	height: 2px;
	width: 30vw;

	margin-top: 20px;

	background-color: #666666;
}

/** 
 * Nav Bar 
 */

 .nav-bar {
	width: 100%;

 	display: flex;
 	flex-direction: row;
 	align-items: center;
 	justify-content: flex-start;
 	margin-left: 32px;
 	margin-top: 16px;
 }

.nav-background {
	height: 64px;
	width: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	background-color: #FFFFFF;
	-webkit-filter: drop-shadow(0 0 10px #CDCDCD);
	        filter: drop-shadow(0 0 10px #CDCDCD);
}

.hamburger {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hamburger-line {
	height: 4px;
	width: 32px;

	margin-top: 4px;

	border-radius: 8px;
	background-color: #666666;
}

/** 
 * Content 
 */

 .content-container {
 	width: 100%;
 }

.body-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin-top: 10vh;
}

.contact-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	font-size: 0.5rem;
	font-family: 'Lato', sans-serif;
	color: #8c8c8c;
}

.tagline {
	font-size: 1rem;
	font-family: 'Lato', sans-serif;
}

.link {
	font-size: 1rem;
	font-family: 'Open Sans', sans-serif;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: #000000;
}

a:hover {
	text-decoration: underline;
	color: #dbdbdb;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

