body {
	background-color: #fcfdff;
}

.root-container {
	height: 95vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

/** 
 * Header 
 */

#title {
	margin-left: 32px;

	font-size: 1.2rem;
	font-family: 'Lato', sans-serif;
}

#border-rect {
	height: 2px;
	width: 30vw;

	margin-top: 20px;

	background-color: #666666;
}

/** 
 * Nav Bar 
 */

 .nav-bar {
	width: 100%;

 	display: flex;
 	flex-direction: row;
 	align-items: center;
 	justify-content: flex-start;
 	margin-left: 32px;
 	margin-top: 16px;
 }

.nav-background {
	height: 64px;
	width: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	background-color: #FFFFFF;
	filter: drop-shadow(0 0 10px #CDCDCD);
}

.hamburger {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hamburger-line {
	height: 4px;
	width: 32px;

	margin-top: 4px;

	border-radius: 8px;
	background-color: #666666;
}

/** 
 * Content 
 */

 .content-container {
 	width: 100%;
 }

.body-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin-top: 10vh;
}

.contact-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	font-size: 0.5rem;
	font-family: 'Lato', sans-serif;
	color: #8c8c8c;
}

.tagline {
	font-size: 1rem;
	font-family: 'Lato', sans-serif;
}

.link {
	font-size: 1rem;
	font-family: 'Open Sans', sans-serif;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
	color: #000000;
}

a:hover {
	text-decoration: underline;
	color: #dbdbdb;
}